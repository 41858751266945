import Cookiebox from "./Cookiebox";

$(document).ready(function () {
  initTextAutoHeight();
  initFleetSlider();
  initFormVisible();
  initSimpleLightbox();
  initMobileMenu();
  new Cookiebox();
});

function initSimpleLightbox() {
  const simpleLightboxExist = document.querySelector('a.lightbox');
  if (simpleLightboxExist) {
    $("a.lightbox").simpleLightbox({
      captions: false,
      showCounter: false,
      disableScroll: false
    });
  }
}

function initFleetSlider() {
  const fleetSliderExist = document.querySelector('.page-fleet .hero__slider');
  if (fleetSliderExist) {
    let homeSlider = new Swiper ('.page-fleet .hero__slider', {
      speed: 500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      loop: true,
      effect: 'fade',
      navigation: {
        nextEl: '.page-fleet .hero__slider .slider__navigation .navigation__next',
        prevEl: '.page-fleet .hero__slider .slider__navigation .navigation__prev'
      }
    });
  }
}

function initFormVisible() {
  const formExist = document.querySelector('.data__form');
  if (formExist) {
    $('.form-open').on('click',function(e) {
      e.preventDefault();
  		$('.page-contact__data .data__form').toggleClass('el-visible');
      $('.page-contact__data .data__outer').toggleClass('el-hidden');
    });
    $('.page-contact__data .data__form .form__close').on('click',function() {
  		$('.page-contact__data .data__form').toggleClass('el-visible');
      $('.page-contact__data .data__outer').toggleClass('el-hidden');
    });
  }
}

function initTextAutoHeight() {
  const textareaExist = document.querySelector('textarea');
  if (textareaExist) {
    autosize(document.querySelectorAll('textarea'));
  }
}

function initMobileMenu() {
  const triggerExist = document.querySelector('.header__trigger');
  if (triggerExist) {
    $('.header__trigger').on('click',function() {
      $(this).toggleClass('el-visible');
  		$('.header__menu').toggleClass('el-visible');
    });
  }
}
